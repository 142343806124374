.transport {
  padding: 60px 0;
  &-row {
    @include make-row();
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 65px;
  }
  &-col {
    @include make-col-ready();
    @include make-col(3);
    padding: 15px;
  }
  &-card {
    text-align: center;
    height: 100%;
    padding: 25px 15px;
    background: $c-border;
    border-radius: 10px;
    &:hover {
      .transport-img {
        transform: scale(1.1);
      }
    }
  }
  &-cnt {
    position: relative;
    height: 151px;
  }
  &-img {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 184px;
    max-height: 151px;
    margin: 0 auto;
    transition: all 0.3s;
  }
  &-txt {
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 25px;
  }
}
@media screen and (max-width: 1200px) {
  .transport {
    &-row {
      margin-left: -10px;
      margin-right: -10px;
    }
    &-col {
      padding: 10px;
    }
    &-card {
      padding: 15px 10px;
    }
    &-cnt {
      height: 131px;
    }
    &-img {
      max-width: 150px!important;
      max-height: 131px;
    }
    &-txt {
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .transport {
    &-txt {
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 980px) {
  .transport {
    &-col {
      @include make-col(4);
    }
    &-card {
      &:hover {
        .transport-img {
          transform: none;
        }
      }
    }
    &-txt {
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 680px) {
  .transport {
    padding: 50px 0 0;
    &-row {
      margin-top: 30px;
    }
    &-col {
      @include make-col(6);
    }
    &-cnt {
      height: 85px;
    }
    &-img {
      max-width: 110px!important;
      max-height: 85px;
    }
    &-txt {
      font-size: 10px;
    }
  }
}