.default-cnt{
	
	ul{
    margin-left: 10px;
    list-style-type: disc;
    list-style-position: inside;
  }
  li{
    font-size: 14px;
    letter-spacing: .02em;
  }
  ol{
    margin-left: 10px;
    list-style-type: decimal;
  }
  h1,h2{
   /*  font-family: $bold; */
    font-size: 24px;
    margin-bottom: 20px;
    line-height: 25px;
  }
  h3,h4,h5{
    font-size: 20px;
    line-height: 21px;
  }
  p{
    font-size: 14px;
    letter-spacing: 0;
    margin-bottom: 15px;
  }

  table{
    margin: 10px 0;
    max-width: 100%;
    width: auto !important;
  }
  td {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 10px 5px;
    vertical-align: middle;
  }
  img {
    max-width: 100%;
  }
  strong,
  b{
   /* font-family: $bold; */
  } 
  i{
    font-style: italic;
  }
  a{
    color: inherit;
     &:hover{
       text-decoration: underline;     
     }
     &:focus{
       outline: none;
     }

  }
}
