#formsendHover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
}
 
.form-loading {
    top: 50%;
    margin-top: -2em;
    left: 50%;
    margin-left: -2em;
    background-color: transparent;
    border: 3px solid #1277c2;
    position: absolute;
    border-radius: 50%;
    border-top-color: transparent;
    border-right-color: transparent;
    width: 2em;
    height: 2em;
    display: inline-block;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-animation: spinner-animation 0.65s infinite linear;
    -moz-animation: spinner-animation 0.65s infinite linear;
    -o-animation: spinner-animation 0.65s infinite linear;
    -ms-animation: spinner-animation 0.65s infinite linear;
    animation: spinner-animation 0.65s infinite linear;
}

.error{
  border-color: red !important;
}

.form-status{
  font-size: 12px;
  color: #00a3dc;
    padding-top: 15px;
    display: block;
    text-align: center;
}

#thanks{
  position: relative;
  width: 300px;
  margin: 0 auto;
  background: #fff;
}

@-webkit-keyframes spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spinner-animation {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes spinner-animation {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-o-keyframes spinner-animation {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(100deg);
  }
}
@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}