input, textarea{
  display: block;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  background: #F0F0F0;
  color: $c-base;
  padding: 10px;
  font-family: $base-font;
  font-weight: 400;
  font-size: 14px;
  outline: none;
  &:focus{
    outline: none;
    color: $c-base;
  }
  &::-webkit-input-placeholder {
    font-family: $base-font;
    font-weight: 400;
    font-size: 14px;
    color: rgba($c-desc, .7);
  }
  &::-moz-placeholder{
    font-family: $base-font;
    font-weight: 400;
    font-size: 14px;
    color: rgba($c-desc, .7);
  }/* Firefox 19+ */
  &:-moz-placeholder{
    font-family: $base-font;
    font-weight: 400;
    font-size: 14px;
    color: rgba($c-desc, .7);
  }/* Firefox 18- */
  &:-ms-input-placeholder{
    font-family: $base-font;
    font-weight: 400;
    font-size: 14px;
    color: rgba($c-desc, .7);
  }
}

input[type='submit'],
button[type='submit']{
  outline: none;
  cursor: pointer;
  font-family: $base-font;
  //@extend .btn;
}

input{
  height: 40px;
  padding: 10px;
}

textarea{
  resize: vertical;
  padding: 10px;
  width: 100%;
}
.wrap-inp{
  margin-right: 15px;
  margin-bottom: 15px;
  flex-grow: 1;
  &:last-of-type {
    margin-right: 0;
  }
  &-row {
    display: flex;
    align-items: baseline;
  }
  &-ttl {
    font-size: 20px;
    font-weight: 700;
    color: $c-inverse;
    margin: 15px 0;
  }
}
.wrap-btn {
  margin-top: 40px;
}
.wrap-select {
  position: relative;
  width: 48%;
  margin-right: 15px;
  margin-bottom: 15px;
  &:last-of-type {
    margin-right: 0;
  }
}
#form-status{
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 16px;
}
#form-status.with_error{
  color: red;
}
.error{
  border-color: red!important;
}
.form-error {
  margin-top: 10px;
  text-align: center;
  color: red;
}
.inp-lbl{
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  color: $c-inverse;
}

.wrap-radio {
  display: flex;
  align-items: center;
  padding: 15px 0;
}
.radio{
  display: none;
  &:checked+.radio-lbl{
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: -2px;
      width: 10px;
      height: 10px;
      margin: auto 5px;
      border-radius: 4px;
      background: $c-primary;
      transition: all .2s;
    }
    &:after{
      border-color: $c-primary;
    }
  }
  &[disabled] +.radio-lbl{
    cursor: default;
    &:before{
      cursor: default;
    }
    &:after{
      background-color: #eee;
    }
  }
  &-wrap{
    margin-top: 30px;
  }
  &-el{
    margin-right: 30px;
    &:last-of-type{
      margin-right: 0;
    }
  }
  &-lbl{
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    padding-left: 15px;
    color: $c-inverse;
    cursor: pointer;
    &:before{
      display: none;
      z-index: 1;
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      left: 0;
      width: 12px;
      height: 12px;
      border-radius: 4px;
      background: $c-inverse;
      border: 2px solid $c-desc;
      transition: all .2s;
      z-index: 0;
    }
    &-txt{
      padding-left: 10px;
    }
  }
}
.radio.error ~ .radio-lbl {
  &:after{
    border-color: red;
  }
}
@media screen and (max-width: 680px) {
  .inp-lbl {
    font-size: 10px;
  }
  .wrap-inp-ttl {
    font-size: 12px;
  }
  input {
    height: 28px;
    padding: 5px 10px;
  }
  input, textarea {
    font-size: 10px;
    &::-webkit-input-placeholder {
      font-size: 10px;
    }
    &::-moz-placeholder{
      font-size: 10px;
    }/* Firefox 19+ */
    &:-moz-placeholder{
      font-size: 10px;
    }/* Firefox 18- */
    &:-ms-input-placeholder{
      font-size: 10px;
    }
  }
  .wrap-radio {
    padding: 0 0 10px;
  }
  .radio-lbl {
    font-size: 10px;
    padding-left: 12px;
    padding-top: 2px;
  }
  .wrap-btn {
    margin-top: 5px;
  }
}