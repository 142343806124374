.footer{
  padding: 80px 0 50px;
  background: $c-bg;
  color: $c-inverse;
  &-cnt{
    display: flex;
    justify-content: space-between;
    &-r{
      margin-left: 6%;
      flex-grow: 1;
    }
  }
  &-t{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 75px;
  }
  &-logo{
    max-width: 260px;
    width: 100%;
  }
  &-menu{
    display: flex;
    color: $c-inverse;
    font-size: 16px;
    font-weight: 400;
    &-i{
      padding: 0;
      margin-left: 60px;
    }
    &-lnk{
      &.__active,
      &:hover {
        color: $c-primary;
      }
    }
  }
  &-b{
    padding-top: 50px;
    border-top: 1px solid $c-desc;
    &-cnt{
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      color: $c-desc;
    }
  }
}
@media screen and (max-width: 1200px) {
  .footer {
    &-logo {
      max-width: 200px;
    }
    &-menu {
      &-i {
        margin-left: 20px;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .footer {
    padding: 60px 0 50px;
    &-t {
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 30px;
    }
    &-logo {
      max-width: 200px;
    }
    &-menu {
      justify-content: center;
      &-wrap {
        margin-top: 40px;
        width: 100%;
      }
      &-i {
        margin-left: 15px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
    &-b {
      padding-top: 30px;
    }
  }
}
@media screen and (max-width: 680px) {
  .footer{
    padding: 60px 0 30px;
    &-logo {
      max-width: 190px;
    }
    &-menu {
      flex-wrap: wrap;
      font-size: 12px;
      &-i {
        text-align: center;
        width: 100%;
        margin-left: 0;
        margin-bottom: 18px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    &-b {
      &-cnt {
        font-size: 10px;
      }
    }
  }
}