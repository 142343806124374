.header{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  color: $c-base;
  font-size: 21px;
  font-weight: 400;
  background: $c-inverse;
  &.__fixed{
    background: $c-bg;
    color: $c-inverse;
    box-shadow: 0 -7px 25px 0 rgba($c-primary, .75);
    .header{
      &-cnt{
        padding: 7px 0;
      }
      &-logo{
        display: none;
        &.__inverse{
          display: block;
        }
      }
      &-menu{
        padding-left: 3%;
        &-lnk{
          padding: 10px 0;
          font-size: 14px;
        }
      }
      &-phone{
        font-size: 14px;
      }
      &-r{
        &-l {
          width: 31px;
          height: 31px;
        }
      }
    }
  }
  &-btn{
    &-mob{
      display: none;
      .header{
        &-btn{
          padding: 25px 50px 25px 30px;
          &:after{
            margin: auto 20px auto;
            @extend .s-arrow-primary!optional;
          }
          &:hover{
            background: $c-inverse;
            &:after{
              @extend .s-arrow-primary!optional;
            }
          }
        }
      }
    }
  }
  &-cnt{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px 0;
    &.__show-menu{
      .header{
        &-logo{
          display: none;
          &.__inverse{
            display: block;
          }
        }
        &-menu{
          &-lst{
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            max-width: 100%;
            width: 100%;
            text-align: center;
            background: $c-inverse;
            z-index: 10;
          }
          &-i:first-of-type{
            margin-top: 35px;
            padding: 15px 2% 0;
          }
          &-i{
            text-align: left;
            padding: 0 0;
          }
          &-lnk{
            position: relative;
            display: inline-block;
            padding: 15px 10px 20px 25px;
            font-weight: 400;
            font-size: 21px;
            text-align: left;
            &.__active{
              &:after{
                right: auto;
                bottom: 5px;
                left: 30px;
                width: 85%;
                opacity: 1;
              }
            }
          }
        }
        &-phone{
          &.__mob{
            display: block;
            text-align: left;
            margin-top: 40px;
            margin-left: 50px;
            color: $c-base;
          }
        }
      }
      .menu-mob{
        &:before{
          display: none;
        }
        &-ic{
          &.__active{
            height: 100%;
            &:before,
            &:after{
              right: 0;
              bottom: 0;
              margin: auto;
            }
          }
        }
      }
    }
  }
  &-lnk{
    display: block;
  }
  &-logo{
    display: block;
    height: 23px;
    &.__inverse{
      display: none;
    }
  }
  &-menu{
    padding-left: 4%;
    flex-grow: 1;
    &-lst{
      display: flex;
      font-weight: 400;
      font-size: 16px;
    }
    &-i{
      padding: 0 3%;
      &:first-of-type{
        padding-left: 0;
      }
      &:last-of-type{
        padding-right: 0;
      }
    }
    &-lnk{
      display: block;
      &.__active,
      &:hover{
        color: $c-primary;
      }
    }
  }
  &-r{
    display: flex;
    align-items: center;
    margin-left: 3%;
    &-l {
      position: relative;
      width: 39px;
      height: 39px;
      background: $c-primary;
      border-radius: 4px;
      margin-right: 15px;
    }
    &-ic {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
    &-txt {
      font-size: 13px;
      font-weight: 400;
      color: $c-desc;
      margin-bottom: 5px;
    }
  }
  &-phone{
    position: relative;
    display: block;
    font-weight: 500;
    font-size: 16px;
  }
  .main-b{
    &-r{
      display: none;
    }
  }
}

.menu-mob{
  display: none;
  position: relative;
  width: 25px;
  height: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 11;
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    right: 0;
    height: 2px;
    width: 19px;
    margin: 0;
    background: $c-base;
    z-index: 1;
  }
  &-ic{
    position: relative;
    display: block;
    cursor: pointer;
    height: 100%;
    width: 100%;
    line-height: 1;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 12px;
      left: 0;
      right: 0;
      height: 2px;
      width: 25px;
      margin: 0 auto;
      background: $c-base;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      bottom: 19px;
      left: 0;
      right: 0;
      height: 2px;
      width: 25px;
      margin: 0 auto;
      background: $c-base;
    }
    &.__active{
      border: none;
      height: 30px;
      &:after{
        position: absolute;
        top: 0;
        right: 15px;
        width: 2px;
        height: 25px;
        background-color: $c-base;
        transform: rotate(-45deg);
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 15px;
        width: 2px;
        height: 25px;
        background-color: $c-base;
        transform: rotate(45deg);
      }
    }
  }
}
@media screen and (max-width: 1250px) {
  .header{
    &.__fixed{
      .header-menu-i{
        padding: 0 2%;
      }
    }
    &-menu{
      padding-left: 1%;
      &-lst{
        justify-content: center;
      }
      &-i{
        padding: 0 1%;
      }
    }
    &-logo{
      height: 20px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .menu-mob{
    display: block;
  }
  .header{
    &.__fixed{
      .header{
        &-cnt{
          padding: 18px 0;
        }
        &-menu{
          padding-left: 0;
        }
        &-logo{
          display: none!important;
          position: relative;
          z-index: 99;
          &.__inverse{
            display: block!important;
          }
        }
      }
      .menu-mob{
        &:before{
          background: $c-inverse;
        }
        &-ic{
          &:before,
          &:after{
            background: $c-inverse;
          }
        }
      }
      .__show-menu{
        .header{
          &-menu{
            &-lnk{
              color: $c-base;
            }
          }
          &-logo{
            display: block!important;
            position: relative;
            z-index: 99;
            &.__inverse{
              display: none!important;
            }
          }
        }
        .menu-mob{
          &:before{
            background: $c-base;
          }
          &-ic{
            &:before,
            &:after{
              background: $c-base;
            }
          }
        }
      }
    }
    &-cnt{
      position: relative;
      padding: 18px 0;
      align-items: center;
      &.__show-menu{
        .header-menu-lnk{
          padding: 15px 10px 15px 40px;
        }
      }
    }
    &-logo{
      display: block!important;
      position: relative;
      z-index: 99;
      &.__inverse{
        display: none!important;
      }
    }
    &-r{
      display: none;
    }
    &-menu{
      padding-left: 0;
      flex-grow: unset;
      order: 1;
      &-lst{
        display: none;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .header{
    &-cnt{
      &.__show-menu{
        .header{
          &-menu{
            &-i{
              text-align: center;
              &:first-of-type{
                padding: 30px 0 0;
              }
            }
            &-lnk{
              font-size: 16px;
              padding: 15px 10px 15px 20px;
            }
          }
        }
      }
    }
  }
}