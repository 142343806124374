.mfp-bg {
  position: fixed!important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $c-bg;
  opacity: 1;
  z-index: 1042;
}
.mfp-wrap {
  position: fixed!important;
  top: 0!important;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
}
.popup {
  position: relative;
  margin: 0;
  width: 475px;
  flex-shrink: 0;
  &-btn{
    position: absolute;
    display: none;
    right: 5px;
    top: 10px;
    width: 16px;
    height: 16px;
    z-index: 1;
    &:before,
    &:after{
      position: absolute;
      left: 10px;
      content: ' ';
      height: 16px;
      width: 1px;
      background-color: $c-inverse;
    }
    &:before{
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
    }
  }
}
.mfp-hide {
  display: block!important;
}
@media screen and (max-width: 1200px) {
  .popup {
    width: 430px;
  }
}
@media screen and (max-width: 980px) {
  .popup {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    flex-shrink: unset;
    &-btn{
      display: block;
    }
  }
  .mfp-hide {
    display: none!important;
  }
}