.main {
  padding: 90px 0 20px;
  background-image: url("../img/main.png");
  background-size: cover;
  background-position: right;
  &-cnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-l {
    max-width: 620px;
    width: 100%;
    margin-right: 15px;
    color: $c-inverse;
  }
  &-ttl {
    font-size: 96px;
    font-weight: 600;
  }
  &-txt {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.3;
    padding: 10px 0 15px;
  }
  &-lst {
    display: flex;
    align-items: baseline;
  }
  &-blk {
    margin-right: 5%;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &-i {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    padding-top: 15px;
    &-ic {
      margin-right: 10px;
    }
  }
  &-form {
    max-width: 100%;
    width: 100%;
    height: 700px;
    overflow-y: auto;
    padding: 15px 25px 20px;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.13) 0%, rgba(0, 0, 0, 0.50) 53.12%);
    backdrop-filter: blur(4px);
    flex-shrink: 0;
    &-ttl {
      font-size: 36px;
      font-weight: 600;
      color: $c-inverse;
      margin-bottom: 12px;
    }
    .wrap-btn {
      margin-top: 20px;
    }
  }
  &-btn {
    font-size: 18px;
    font-weight: 700;
    padding: 15px 10px;
    max-width: 200px;
    width: 100%;
    &.__mob {
      font-size: 13px;
    }
    &-mob {
      display: none;
    }
    &-add {
      font-family: $base-font;
      font-size: 14px;
      font-weight: 700;
      color: $c-inverse;
      padding: 5px 0;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
    &-ic {
      vertical-align: middle;
    }
    &-txt {
      vertical-align: middle;
      padding-left: 5px;
    }
    &-remove {
      font-family: $base-font;
      font-size: 14px;
      font-weight: 700;
      color: red;
      padding: 5px 0;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      margin-left: 10px;
      &.__disable {
        color: $c-inverse;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .main {
    &-ttl {
      font-size: 64px;
    }
    &-txt {
      font-size: 24px;
    }
    &-blk {
      margin-right: 3%;
    }
    &-i {
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 980px) {
  .main {
    padding: 110px 0 210px;
    background-image: url("../img/main-mob.png");
    background-position: left;
    &-l {
      max-width: 100%;
      margin-right: 0;
    }
    &-ttl {
      text-align: center;
    }
    &-txt {
      text-align: center;
    }
    &-lst {
      justify-content: center;
    }
    &-form {
      display: none;
      background: $c-bg;
      height: 400px;
      padding: 20px 20px 50px;
      & .wrap-btn {
        margin-top: 0;
        .main-btn {
          font-size: 13px;
          padding: 10px;
          max-width: 140px;
        }
      }
      &-ttl {
        font-size: 26px;
      }
    }
    &-btn {
      &-mob {
        display: block;
      }
      &.__mob {
        display: block;
        margin: 40px auto 0;
        max-width: 140px;
      }
      &-add {
        font-size: 10px;
      }
      &-ic {
        width: 16px;
        height: 17px;
      }
      &-remove {
        font-size: 10px;
      }
    }
  }
  .mfp-content {
    .main-form {
      display: block;
    }
  }
}
@media screen and (max-width: 680px) {
  .main {
    &-ttl {
      font-size: 48px;
    }
    &-txt {
      font-size: 21px;
    }
    &-i {
      font-size: 13px;
      font-weight: 500;
      &-ic {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }
}