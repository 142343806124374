.ttl {
  &-t {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    color: $c-inverse;
    padding: 8px 10px;
    background: $c-primary;
    border-radius: 5px;
    margin-bottom: 25px;
  }
  &-txt {
    font-size: 56px;
    font-weight: 600;
    margin-bottom: 20px;
    &.__light {
      color: $c-inverse;
      line-height: 1.1;
    }
  }
  &-desc {
    font-size: 28px;
    font-weight: 400;
  }
}
@media screen and (max-width: 1200px) {
  .ttl {
    &-txt {
      font-size: 50px;
    }
  }
}
@media screen and (max-width: 980px) {
  .ttl {
    &-t {
      font-size: 12px;
      margin-bottom: 20px;
    }
    &-txt {
      font-size: 46px;
      margin-bottom: 20px;
    }
    &-desc {
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 680px) {
  .ttl {
    &-t {
      font-size: 9px;
    }
    &-txt {
      font-size: 27px;
      margin-bottom: 10px;
    }
    &-desc {
      font-size: 15px;
      line-height: 1.3;
    }
  }
}