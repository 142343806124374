//Bootstarp grid
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import '../../../node_modules/bootstrap/scss/_mixins.scss';
@import '../../../node_modules/bootstrap/scss/_grid.scss';

*{
  box-sizing: border-box;
}
html,
body{
  height: 100%;
  line-height: 1;
  color: $c-base;
  font-size: 16px;
  font-family: $base-font;
  font-weight: 400;
  background: $c-inverse;
  &.__overlay {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }
}

.container{
  max-width: $width-container + ($padding-container * 2);
  @include make-container(); /* align center, add default padding*/
  padding-left: $padding-container;
  padding-right: $padding-container;
}

.col{
  @include make-col-ready();
  @include make-col(4);

}
.row{
  @include make-row();
}

.h-100{
  height: 100%;
}

.d-flex{
  display: flex;
  align-items: center;
}
/* footer to bottom of page */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
}
.cnt-wrap {
  position: relative;
  flex: 1 0 auto;
}
.footer {
  flex: 0 0 auto;
}
/* end footer to bottom of page */

@media screen and (max-width: 1400px){
  .container{
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 800px){
  .container{
    padding-left: 20px;
    padding-right: 20px;
  }
  .__pl {
    padding-left: 0;
  }
}