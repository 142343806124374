img{
  display: block;
  max-width: 100%;
}
a{
  color: inherit;
  text-decoration: none;
  outline: none;
  transition: all 0.3s;
}
.clr:after{
  content:'';
  display: table;
  clear: both;
}
.__hidden{
  display: none;
}
.tel{
  pointer-events: none;
  cursor: default;
}
.__disable{
  pointer-events: none;
  cursor: default;
  opacity: .5;
}
.center-ic{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.__pl{
  padding-left: 12%;
}
.__pt-0{
  padding-top: 0!important;
}
.pl-0{
  padding-left: 0!important;
}
.pr-0{
  padding-right: 0!important;
}
.p-r{
  position: relative;
}
.__transparent{
  background: transparent!important;
}
.txt-dark{
  color: $c-base!important;
}
.__visible_hidden{
  visibility: hidden;
}

.content{
  content: '';
  display: block;
  position: absolute;
}
.z-1{
  z-index: 1;
}
.w-100{
  width: 100%;
}
.lnk{
  border-bottom: 1px solid $c-base;
}
.txt-center{
  text-align: center;
}
.page{
  font-weight: 300;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 25px;
  color: $c-desc;
}
.d-block{
  display: block;
}
.owl-theme .owl-dots{
  text-align: left;
}
.owl-theme .owl-dots .owl-dot span{
  width: 9px;
  height: 9px;
  margin: 5px 4px;
}
.owl-theme .owl-dots .owl-dot:first-of-type{
  margin-left: 0;
}
.owl-theme .owl-dots .owl-dot span{
  background: $c-primary;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: $c-base;
}
.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 70px;
}

.section {
  background: linear-gradient(180deg, rgba(233, 181, 0, 0) 0%, rgba(233, 181, 0, .1) 20.83%, rgba(233, 181, 0, .25) 43.23%, rgba(233, 181, 0, .5) 64.58%, rgba(233, 181, 0, .75) 82.29%, #E9B500 100%);
}

/* INPUTS */
@import "inputs";
/* END INPUTS */

/* BUTTONS */
@import "buttons";
/* END BUTTONS */

/* TITLES */
@import "titles";
/* END TITLES */


@media screen and (max-width: 900px){
  .tel{
    pointer-events: auto;
    cursor: pointer;
  }
  .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 40px;
  }
}
@media screen and (max-width: 680px) {
  .page{
    font-size: 14px;
    margin-bottom: 15px;
  }
  .owl-theme .owl-dots .owl-dot span {
    width: 6px;
    height: 6px;
    margin: 5px 3px;
  }
}
