.contacts {
  padding: 60px 0 95px;
  &-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-form {
    max-width: 475px;
    width: 100%;
    padding: 40px 25px 20px;
    border-radius: 10px;
    background: $c-bg;
    margin-right: 8%;
  }
  &-cnt {
    flex-grow: 1;
  }
  &-lst {
    margin: 40px 0;
  }
  &-i {
    margin-bottom: 30px;
    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  &-lnk {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    &-soc {
      position: relative;
      display: block;
      width: 48px;
      height: 48px;
      margin-right: 15px;
      background: $c-bg;
      border: 1px solid $c-bg;
      border-radius: 5px;
      & svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
      &:hover {
        background: $c-primary;
        border-color: $c-inverse;
      }
    }
  }
  &-ic {
    vertical-align: middle;
  }
  &-txt {
    vertical-align: middle;
    padding-left: 5px;
  }
  &-b {
    display: flex;
    align-items: center;
  }
  &-btn {
    font-size: 18px;
    font-weight: 700;
    padding: 15px 10px;
    max-width: 200px;
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .contacts {
    &-form {
      max-width: 430px;
    }
  }
}
@media screen and (max-width: 980px) {
  .contacts {
    &-wrap {
      flex-wrap: wrap;
    }
    &-form {
      max-width: 100%;
      margin-right: 0;
    }
    &-cnt {
      margin-bottom: 40px;
      order: -1;
    }
  }
}
@media screen and (max-width: 680px) {
  .contacts {
    padding: 40px 0 50px;
    &-lst {
      margin: 30px 0 20px;
    }
    &-form {
      padding: 40px 20px 10px;
      & .wrap-btn {
        margin-top: 30px;
      }
    }
    &-btn {
      font-size: 13px;
      padding: 10px;
      max-width: 140px;
    }
  }
}