.loaderWrapper{
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  opacity: .60;
}

.loader, .loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
}

.loader {
  border: 1.1em solid rgba(48,48,48,0.25);
  margin: 200px auto;
  position: fixed;
  top: 20%;
  left: 50%;
  text-indent: -9999em;
  border-left: 1.1em solid #434343;
  /*-webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);*/
  -webkit-animation: mymove 5s infinite linear;
  animation: mymove 1.2s infinite linear;
}
@keyframes mymove {

  100% {transform: rotate(360deg)}
}

@-webkit-keyframes mymove {
  100% {transform: rotate(360deg)}
}