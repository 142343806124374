.reasons {
  padding: 60px 0;
  &-row {
    @include make-row();
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 80px;
  }
  &-col {
    @include make-col-ready();
    @include make-col(4);
    padding: 15px;
  }
  &-card {
    height: 100%;
    padding: 32px 30px 45px;
    background: $c-border;
    border-radius: 10px;
  }
  &-ttl {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    margin: 20px 0 10px;
  }
  &-txt {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
  }
}
@media screen and (max-width: 1200px) {
  .reasons {
    &-card {
      padding: 30px 25px 35px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .reasons {
    &-col {
      @include make-col(6);
    }
  }
}
@media screen and (max-width: 680px) {
  .reasons {
    padding: 30px 0;
    &-row {
      margin-left: -10px;
      margin-right: -10px;
    }
    &-col {
      @include make-col(12);
      padding: 10px;
    }
    &-txt {
      font-size: 14px;
    }
  }
}