.btn{
  position: relative;
  display: inline-block;
  font-weight: 400;
  font-size: 18px;
  color: $c-inverse;
  text-align: center;
  padding: 20px 40px 20px 35px;
  border: 1px solid $c-primary;
  border-radius: 10px;
  background: $c-primary;
  &:hover{
    background: $c-primary;
    border-color: $c-primary;
    box-shadow: rgba($c-primary, .3) 0 3px 8px;
  }
}
.btn-primary{
  @extend .btn;
  background: $c-primary;
  border-color: $c-primary;
}
@media screen and (max-width: 680px) {
  .btn, .btn-primary {
    border-radius: 4px;
  }
}