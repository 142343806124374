.reviews {
  padding: 90px 0;
  &-wrap {
    position: relative;
  }
  &-slider {
    padding: 80px 0 40px;
    &.owl-carousel .owl-stage {
      display: flex;
    }
  }
  &-i {
    display: flex;
    flex: 1 0 auto;
    height: 100%;
    padding: 40px 30px 45px;
    background: $c-inverse;
    border-radius: 10px;
    &-cnt {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
    }
  }
  &-txt {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    padding-bottom: 15px;
  }
  &-ttl {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
  }
  &-nav {
    position: absolute;
    display: flex;
    width: 140px;
    bottom: 0;
    right: 0;
    &-btn {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      width: 61px;
      height: 61px;
      line-height: 61px;
      text-align: center;
      margin: 0;
      border-radius: 50%;
      border: 1px solid $c-bg;
      background: $c-bg;
      transition: all .2s;
      cursor: pointer;
      z-index: 2;
      &.__next {
        right: 0;
        left: auto;
        margin: 0;
      }
      &:hover {
        background: $c-inverse;
        border-color: $c-inverse;
      }
    }
    &-ic {
      vertical-align: middle;
    }
  }
}
@media screen and (max-width: 680px) {
  .reviews {
    padding: 50px 0 30px;
    &-slider {
      padding: 20px 0;
    }
    &-i {
      padding: 40px 25px 45px;
    }
    &-txt {
      font-size: 14px;
    }
    &-ttl {
      font-size: 15px;
    }
    &-nav {
      width: 70px;
      &-btn {
        width: 30px;
        height: 30px;
        line-height: 26px;
      }
      &-ic {
        width: 12px;
        height: 12px;
      }
    }
  }
}