.about {
  padding: 60px 0;
  &-cnt {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-top: 60px;
    margin: 0 6%;
  }
  &-card {
    text-align: center;
    margin-right: 3%;
    &:last-of-type {
      margin-right: 0;
    }
    &-ttl {
      font-size: 90px;
      font-weight: 500;
      color: $c-primary;
      margin-bottom: 20px;
    }
    &-note {
      font-size: 30px;
      font-weight: 600;
      padding-left: 5px;
    }
    &-txt {
      font-size: 20px;
      font-weight: 400;
      color: $c-desc;
    }
  }
}
@media screen and (max-width: 980px) {
  .about {
    &-cnt {
      padding-top: 25px;
      margin: 0;
    }
    &-card {
      &-ttl {
        font-size: 60px;
        margin-bottom: 15px;
      }
      &-txt {
        font-size: 18px;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .about {
    padding: 40px 0 20px;
    &-cnt {
      flex-wrap: wrap;
    }
    &-card {
      margin-right: 10px;
      &:last-of-type {
        width: 100%;
        margin-top: 20px;
        margin-right: 0;
      }
      &-ttl {
        font-size: 45px;
      }
      &-txt {
        font-size: 10px;
      }
    }
  }
}