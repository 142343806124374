.steps {
  padding: 90px 0;
  background: $c-bg;
  &-row {
    display: flex;
    justify-content: space-between;
  }
  &-l {
    width: 100%;
    min-height: 1px;
    flex: 0 0 37%;
    max-width: 37%;
    margin-right: 15px;
  }
  &-lst {
    margin-top: 30px;
    list-style-type: none;
    counter-reset: css-counter 0;
  }
  &-i {
    position: relative;
    padding-left: 80px;
    padding-bottom: 5px;
    margin-bottom: 35px;
    counter-increment: css-counter 1;
    &:before {
      content: counter(css-counter);
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      width: 40px;
      padding: 12px 0;
      background: $c-inverse;
      border-radius: 5px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 1px;
      margin: 56px 0 0 19px;
      background: rgba(203, 203, 203, .8);
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &.__active {
      &:before {
        color: $c-inverse;
        background: $c-primary;
      }
      &:after {
        display: none;
      }
    }
  }
  &-ttl {
    font-size: 28px;
    font-weight: 600;
    color: $c-primary;
    margin-bottom: 25px;
  }
  &-txt {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    color: $c-inverse;
  }
}
@media screen and (max-width: 980px) {
  .steps {
    &-row {
      flex-wrap: wrap;
    }
    &-l {
      margin-right: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }
    &-lst {
      margin-top: 20px;
    }
  }
}
@media screen and (max-width: 680px) {
  .steps {
    padding: 50px 0;
    &-i {
      padding-bottom: 3px;
      padding-left: 65px;
      margin-bottom: 20px;
      &:before {
        width: 32px;
        padding: 8px 0;
      }
      &:after {
        margin: 50px 0 0 16px;
      }
    }
    &-ttl {
      font-size: 23px;
      padding-top: 4px;
    }
    &-txt {
      font-size: 14px;
    }
  }
}