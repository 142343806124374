.receive {
  padding: 90px 0;
  background-image: url("../img/receive.png");
  background-size: cover;
  &-cnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-txt {
    font-size: 56px;
    font-weight: 600;
    line-height: 1.2;
    max-width: 680px;
  }
  &-form {
    max-width: 480px;
    width: 100%;
    height: 680px;
    overflow-y: auto;
    padding: 40px 25px 20px;
    border-radius: 10px;
    background: $c-bg;
    margin-left: 3%;
    flex-shrink: 0;
    .wrap-btn {
      margin-top: 20px;
    }
  }
  &-btn {
    font-size: 18px;
    font-weight: 700;
    padding: 15px 10px;
    max-width: 200px;
    width: 100%;
    &-add {
      font-family: $base-font;
      font-size: 14px;
      font-weight: 700;
      color: $c-inverse;
      padding: 5px 0;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
    &-ic {
      vertical-align: middle;
    }
    &-txt {
      vertical-align: middle;
      padding-left: 5px;
    }
    &-remove {
      font-family: $base-font;
      font-size: 14px;
      font-weight: 700;
      color: red;
      padding: 5px 0;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      margin-left: 10px;
      &.__disable {
        color: $c-inverse;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .receive {
    &-txt {
      font-size: 50px;
      margin-right: 15px;
    }
    &-form {
      max-width: 430px;
    }
  }
}
@media screen and (max-width: 980px) {
  .receive {
    &-cnt {
      flex-wrap: wrap;
    }
    &-txt {
      font-size: 40px;
      max-width: 100%;
      margin-bottom: 40px;
      margin-right: 0;
    }
    &-form {
      padding: 25px 18px 20px;
      margin-left: 0;
      max-width: 100%;
      background: linear-gradient(0deg,rgba(0,0,0,.5) 0,rgba(0,0,0,.5) 100%),linear-gradient(180deg,rgba(0,0,0,.5) 0,rgba(0,0,0,.5) 53.12%);
      backdrop-filter: blur(4px);
    }
  }
}
@media screen and (max-width: 680px) {
  .receive {
    padding: 50px 0;
    background-position: top;
    &-txt {
      font-size: 29px;
    }
    &-form {
      height: 520px;
      & .wrap-btn {
        margin-top: 5px;
      }
    }
    &-btn {
      font-size: 13px;
      padding: 10px;
      max-width: 140px;
      &-ic {
        width: 16px;
        height: 17px;
      }
      &-add {
        font-size: 10px;
      }
      &-remove {
        font-size: 10px;
      }
    }
  }
}